import * as React from "react";
import Layout from "../../components/layout";

// styles

// data

// markup
const CommercePage = () => {
  return (
    <Layout
      title=">特定商取引法に基づく表示 | CO Record &amp; journal"
      policyStyle={true}
      showHeader={true}
      isTop={false}
    >
      <main id="id-main" className="policy-body">
        <section>
          <div className="contents">
            <div className="text-box">
              <br />
              <br />
              <br />

              <h3>特定商取引法に基づく表示</h3>
              <p>特定商取引法に基づく表示</p>
              <p>
                ■ サービスの名称
                <br />
                CO Record &#x26; Journal（以下「本サービス」といいます。）
              </p>
              <p>
                ■ 販売業者
                <br />
                帝人株式会社
              </p>
              <p>
                ■ 所在地
                <br />
                〒 100-8585
                <br />
                東京都千代田区霞が関 3-2-1
                <br />
                霞が関コモンゲート西館
              </p>
              <p>
                ■ お問い合わせ
                <br />
                問い合わせフォーム
                <a href="https://dive-co.net/">（https://dive-co.net/）</a>
                <br />
                営業時間　平日 10 時 00 分～ 17 時 00 分　
                <br />
                ※土日祝休日、年末年始の期間を除きます
              </p>
              <p>
                ■ 運営責任者
                <br />
                データソリューション事業開発部長　増村成嗣
              </p>
              <p>
                ■ 販売価格（サービス料金）
                <br />
                本サービスの販売価格は、本サービスのアプリ（以下「本アプリ」といいます。）内の購入画面に表示いたします。表示価格には消費税を含みます。
              </p>
              <p>
                ■ 販売価格（サービス料金）以外に利用者が負担すべき費用
                <br />
                インターネット接続に必要なプロバイダ料金、データ通信料、その他インターネット利用のために必要となる料金は、ユーザーのご負担となります。
              </p>
              <p>
                ■ 代金支払方法・支払時期
                <br />
                お支払いはアプリ内課金となります。支払い方法・支払い時期については、Apple
                Inc.（iTunes 株式会社）または Google
                Inc.等の、購入にかかる決済を代行する会社の定める規約・約款等に基づきます。
              </p>
              <p>
                ■ 本サービスの提供時期
                <br />
                本サービス（有償サービスを除きます。）は、システムトラブルや回線の混雑等の場合を除き、本アプリをダウンロードした後、すぐにご利用いただけます。
                <br />
                本サービスのうち有償サービスは、所定の利用料金のお支払い手続完了後、直ちにご利用いただけます。
              </p>
              <p>
                ■ 返品・交換・返金
                <br />
                本サービスの性質上、サービスご利用開始後の返品・交換、返金等には応じられません。
              </p>
              <p>
                ■ 動作環境
                <br />
                ・本アプリが動作する Android 端末、iOS
                端末のいずれかが必要です。推奨する動作環境については、こちら
                <a href="https://dive-co.net/supported-devices/">
                  （https://dive-co.net/supported-devices/）
                </a>
                をご確認ください。
                <br />
                ※OS
                のバージョンアップ等に伴う対応のために一時的に、全部または一部の機能の利用が制限される場合があります。
                <br />
                ※何らかの理由により、本アプリが利用いただけない場合があります。その際、本アプリのシステムの復旧にお時間をいただくことがございます。
                <br />
                ・上記以外の環境でのご利用で生じたトラブルに関して、当社は一切責任を負いかねますので、あらかじめご了承ください。
              </p>
              <p>
                ■ その他
                <br />
                ・本サービスに関する利用規約、プライバシーポリシーを必ず確認し、同意した上で、本アプリをご利用ください。
              </p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default CommercePage;
